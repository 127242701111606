import React from 'react'
import Img from 'gatsby-image'

const ImgWithGIFSupport = ({ image, ...rest }) => {
  if (!image) return <></>
  if (image.mime_type === 'image/gif') {
    return <img src={image.source_url} {...rest} alt={image.title} />
  }
  if (image && !!image.localFile && !!image.localFile.childImageSharp)
    return <Img fluid={image.localFile.childImageSharp.fluid} {...rest} />
}

export default ImgWithGIFSupport
