import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { InlineShareButtons } from 'sharethis-reactjs'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ImgWithGIFSupport from '../components/ImgWithGIFSupport'
import ImgWithSVGSupport from '../components/ImgWithSVGSupport'

const PostArticle = ({ post, appleSVG, spotifySVG }) => {
  const { acf } = post
  const { watch_read_listen_post: postObj } = acf
  const isImagePlaceholder =
    postObj[0].slug !== '768' && postObj[0].slug !== 'placeholder_image'

  if (postObj[0].__typename === 'WordPressAcf_read') {
    const { read_repeate } = postObj[0]
    return (
      <>
        <ImgWithGIFSupport
          image={postObj[0].image}
          className="img-fluid m-0 shadow"
          alt={postObj.title}
          style={{ height: '400px' }}
        />
        <article
          className="mt-4 post-content"
          dangerouslySetInnerHTML={{ __html: postObj[0].editor }}
        />
        {read_repeate &&
          read_repeate.map((subPost, index) => {
            const { title, text, image } = subPost
            return (
              <article className="mt-4" key={index}>
                <h2
                  className="h3 text-primary mb-0"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                {isImagePlaceholder ? (
                  <div className="row">
                    <div className="col-md-4 mt-4 my-auto">
                      <ImgWithGIFSupport
                        image={image}
                        className="img-fluid m-0 shadow"
                        alt={title}
                      />
                    </div>
                    <div
                      className="col-md-8 mt-4"
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  </div>
                ) : (
                  <article dangerouslySetInnerHTML={{ __html: text }} />
                )}
              </article>
            )
          })}
      </>
    )
  }

  if (postObj[0].__typename === 'WordPressAcf_listen') {
    const { podcast_platform } = postObj[0]
    const { apple_link, spotify_link } = podcast_platform
    return (
      <article className="mt-4">
        <h2
          className="text-primary mb-3"
          dangerouslySetInnerHTML={{ __html: postObj.title }}
        />
        {isImagePlaceholder ? (
          <>
            <ImgWithGIFSupport
              image={postObj[0].image}
              className="img-fluid m-0 shadow"
              alt={postObj.title}
              style={{ height: '400px' }}
            />
            <div
              className="mt-4"
              dangerouslySetInnerHTML={{ __html: postObj[0].editor }}
            />
          </>
        ) : (
          <article dangerouslySetInnerHTML={{ __html: postObj[0].editor }} />
        )}
        {podcast_platform && (
          <div className="d-flex flex-column flex-sm-row justify-content-center text-center mt-4">
            {!!podcast_platform.apple_link && (
              <a
                href={apple_link}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-3 mr-sm-3"
              >
                <ImgWithSVGSupport {...appleSVG} loading="lazy" height="50" />
              </a>
            )}
            {!!podcast_platform.spotify_link && (
              <a
                href={spotify_link}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-3"
              >
                <ImgWithSVGSupport {...spotifySVG} loading="lazy" height="50" />
              </a>
            )}
          </div>
        )}
      </article>
    )
  }

  return <></>
}

const ReadSinglePostTemplate = ({ data }) => {
  const { appleSVG, spotifySVG, wordpressPost, wordpressAcfOptions } = data
  const StaticPost = wordpressPost
  const sidebar = wordpressAcfOptions.options.covid19_sidebar

  const {
    related_content_title,
    related_content,
    education_content_title,
    education_content,
  } = sidebar
  const [isShareActived, setShareStage] = useState(false)

  const handleShareClick = () => {
    setShareStage(!isShareActived)
  }

  return (
    <Layout>
      <SEO title={StaticPost.title} />
      <section className="bg-dark text-white py-5">
        <div className="container">
          <div className="d-flex mb-3">
            <button
              type="button"
              onClick={() => {
                window.history.back()
              }}
              className="btn btn-outline-primary border-0 rounded-0 btn-back-hover text-uppercase text-white my-auto font-weight-bold"
            >
              Back
            </button>
          </div>
          <div className="row">
            <div className="col-lg-8 mt-4">
              <h1
                className="font-weight-bold mb-4 text-uppercase"
                dangerouslySetInnerHTML={{ __html: StaticPost.title }}
              />
              <div className="small d-flex mb-3">
                <div className="d-flex mr-3">
                  <span className="material-icons text-primary mr-2">
                    schedule
                  </span>
                  <span className="my-auto">{StaticPost.date}</span>
                </div>
                <div className="d-flex mr-3">
                  <span className="material-icons text-primary mr-2">
                    person
                  </span>
                  <span className="my-auto text-capitalize">
                    {StaticPost.author.name}
                  </span>
                </div>
                <div className="d-flex position-relative">
                  <span className="material-icons text-primary mr-2">
                    share
                  </span>
                  <button
                    className="btn-reset my-auto cursor-pointer text-white post-link-hover"
                    onClick={handleShareClick}
                    onKeyDown={handleShareClick}
                  >
                    Share
                  </button>
                </div>
              </div>
              <div
                className={`${
                  isShareActived
                    ? 'postShareButtons-active mb-3'
                    : 'postShareButtons'
                }`}
              >
                <InlineShareButtons
                  config={{
                    alignment: 'left', // alignment of buttons (left, center, right)
                    color: 'social', // set the color of buttons (social, white)
                    enabled: true, // show/hide buttons (true, false)
                    font_size: 13, // font size for the buttons
                    labels: 'cta', // button labels (cta, counts, null)
                    language: 'en', // which language to use (see LANGUAGES)
                    networks: [
                      // which networks to include (see SHARING NETWORKS)
                      'instagram',
                      'facebook',
                      'youtube',
                      'linkedin',
                      'twitter',
                    ],
                    padding: 0, // padding within buttons (INTEGER)
                    radius: 0, // the corner radius on each button (INTEGER)
                    show_total: false,
                    size: 35, // the size of each button (INTEGER)
                  }}
                />
              </div>

              {StaticPost.content && (
                <article
                  dangerouslySetInnerHTML={{ __html: StaticPost.content }}
                />
              )}

              <PostArticle
                post={StaticPost}
                appleSVG={appleSVG}
                spotifySVG={spotifySVG}
              />
            </div>
            <div className="col-lg-4 border-left border-secondary mt-4">
              <h3 className="h4">{related_content_title}</h3>
              {related_content && (
                <ul className="list-unstyled d-flex flex-column p-0">
                  {related_content.map((related_content, index) => (
                    <li
                      className="position-relative d-block mb-1 ul-link-item mt-2"
                      key={index}
                    >
                      <u>
                        <a
                          href={related_content.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="small text-primary font-weight-light"
                        >
                          {related_content.title}
                        </a>
                      </u>
                    </li>
                  ))}
                </ul>
              )}
              <h3 className="h4 d-block mt-4">{education_content_title}</h3>
              {education_content.map((education, index) => (
                <Link
                  to={`/video/${education.playlist_id}/`}
                  className="d-block text-decoration-none mb-4"
                  key={index}
                >
                  {education.image &&
                    education.image.localFile &&
                    education.image.localFile.childImageSharp && (
                      <Img
                      fluid={education.image.localFile.childImageSharp.fluid}
                        alt={education.name}
                        className="shadow"
                      />
                    )}
                  <h3 className="small text-white mt-2 mb-1 text-uppercase font-weight-normal">
                    {education.name}
                  </h3>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ReadSinglePostTemplate
export const pageQuery = graphql`
  query($id: Int!) {
    appleSVG: file(
      relativePath: { eq: "podcast/us_uk_apple_podcasts_listen_badge_rgb.svg" }
    ) {
      ...PlaceholderImageWithSVG
    }
    spotifySVG: file(
      relativePath: { eq: "podcast/spotify-podcast-badge-blk-grn-165x40.svg" }
    ) {
      ...PlaceholderImageWithSVG
    }
    wordpressPost(wordpress_id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      author {
        name
      }
      acf {
        watch_read_listen_post {
          __typename
          ... on WordPressAcf_read {
            editor
            image {
              title
              slug
              source_url
              mime_type
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            read_repeate {
              title
              text
              image {
                title
                slug
                source_url
                mime_type
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_listen {
            editor
            podcast_platform {
              apple_link
              spotify_link
            }
            image {
              title
              slug
              source_url
              mime_type
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        covid19_sidebar {
          related_content_title
          related_content {
            title
            link
          }
          education_content_title
          education_content {
            name
            playlist_id
            image {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
